import Landing from "./Landing";
import './scss/app.scss';

function App() {
  return (
    <Landing />
  );
}

export default App;
