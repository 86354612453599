import React, { useState } from 'react'
import logo from './assets/logo.png'
import lights from './assets/lights.png'
import galaxy from './assets/galaxy.png'
import appScreen from './assets/appScreen.png'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

const Header = () => {
  return (
    <header className="header">
      <nav className="mx-auto flex justify-between items-center p-6" aria-label="Global">
        <img className="h-8 w-auto" src={logo} alt="logo" />
        <button className='colored-btn'>Launch App</button>
      </nav>
    </header >
  )
};

const Accordion = () => {
  const accordionData = [
    {
      title: '1. What is Arcanix?',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    },
    {
      title: '2. How can I get access to the bot?',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    },
    {
      title: '3. What operating system does Arcanix bot support?',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    },
    {
      title: '4. How can I contact the team?',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    }
  ];

  const [activeItem, setActiveItem] = useState(null);

  const handleShow = index => {
    index === activeItem ? setActiveItem(null) : setActiveItem(index)
  }

  return (
    <div className="accordion">
      {accordionData.map((item, index) => (
        <div className="accordion-item" key={index}>
          <div className="accordion-title" onClick={() => handleShow(index)}>
            <div>{item.title}</div>
            {activeItem === index ?
              <ChevronUpIcon className="h-4 w-4" />
              :
              <ChevronDownIcon className="h-4 w-4" />
            }
          </div>
          {activeItem === index && <div className="accordion-content">{item.content}</div>}
        </div>
      ))}
    </div>

  );
};

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <img className="h-8 w-auto" src={logo} alt="logo" />
        <div className='flex'>
          <div className='list-group'>
            <div className='footer-list-header'>Product</div>
            <div className='footer-list-item'>Overview</div>
            <div className='footer-list-item'>Collection</div>
            <div className='footer-list-item'>Wallet alert</div>
          </div>
          <div className='list-group'>
            <div className='footer-list-header'>Contact</div>
            <div className='footer-list-item'>Discord</div>
            <div className='footer-list-item'>Twitter</div>
            <div className='footer-list-item'>Email</div>
          </div>
        </div>
      </footer>
      <div className='copyright-section'>
        <p>Copyright © 2023 Realm Software Inc. All rights reserved.</p>
        <div className='flex'>
          <p className='clickable'>Privacy Policy</p>
          <p className='clickable'>Terms of Service</p>
        </div>
      </div>
    </>
  )
};

export default function Landing() {
  return (
    <>
      <Header />
      <img className="lights" src={lights} alt="lights" />
      <div className='banner-badge'>Everything on track</div>
      <div className='banner-white'>Arcanix automatically mints</div>
      <div className='banner-colored'>the next NFT collections</div>
      <div className='sub-banner'>Automate your access and minting for awaited NFT collections</div>
      <img className="galaxy" src={galaxy} alt="galaxy" />
      <img className="app-screen" src={appScreen} alt="appScreen" />
      <div className='faq-header'>FAQ</div>
      <Accordion />
      <Footer />
    </>
  )
}
